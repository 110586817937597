import type { Action } from 'react-sweet-state';
import { createStore, createActionsHook, createHook } from 'react-sweet-state';

type Nav4FeedbackCollectorState = {
	isOpen: boolean;
	optingOut: boolean;
};

const initialState: Nav4FeedbackCollectorState = {
	isOpen: false,
	optingOut: false,
};

const actions: Record<string, () => Action<Nav4FeedbackCollectorState>> = {
	openFeedbackCollector:
		() =>
		({ setState }) => {
			setState({
				isOpen: true,
				optingOut: false,
			});
		},
	openOptOutModal:
		() =>
		({ setState }) => {
			setState({
				isOpen: true,
				optingOut: true,
			});
		},
	close:
		() =>
		({ setState }) => {
			setState({
				isOpen: false,
				optingOut: false,
			});
		},
};

const store = createStore({
	initialState,
	actions,
	name: 'Nav4FeedbackCollectorStore',
});

export const useNav4FeedbackCollectorStore = createHook(store);
export const useNav4FeedbackCollectorActions = createActionsHook(store);
