import React, { useContext } from 'react';

import { LoadableLazy } from '@confluence/loadable';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { UpFlowContext } from '@confluence/change-edition/entry-points/UpFlowProvider';

const LazyExperimentPlanSelectionModal = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ExperimentPlanSelectionModal" */ './ExperimentPlanSelectionModal'
			)
		).ExperimentPlanSelectionModal,
});

export const TrialPlanComparisonModalMountComponent = () => {
	const { currentEdition } = useContext(UpFlowContext);
	return <>{currentEdition === ConfluenceEdition.FREE && <LazyExperimentPlanSelectionModal />}</>;
};
