import { useEffect } from 'react';

import { addUFOCustomData } from '@atlaskit/react-ufo/custom-data';

import { useIsNav4Enabled } from './useIsNav4Enabled';

export const useAddUFOCustomDataForNavType = () => {
	const isNav4Enabled = useIsNav4Enabled();

	// Adds the "custom:confluence:navType" property to UFO page load event to enable
	// Nav3/Nav4 split in the performance portal. Must be called on each route as it
	// only adds custom data to the existing interaction
	useEffect(() => {
		addUFOCustomData({ 'confluence:navType': isNav4Enabled ? 'nav4' : 'nav3' });
	});
};
