// A component that opens the modal when the user is eligible

import React from 'react';

import { LoadableLazy } from '@confluence/loadable';
import {
	ErrorDisplay,
	withTransparentErrorBoundary,
	Attribution,
} from '@confluence/error-boundary';

import { useModernizeModal } from '../hooks/useModernizeModal';

export const ModernizeModal = withTransparentErrorBoundary({
	attribution: Attribution.CC_ONBOARDING,
})(() => {
	const { eligible, loading, error, dismiss } = useModernizeModal();

	if (error) {
		return <ErrorDisplay error={error} />;
	}

	if (loading || !eligible) {
		return null;
	}

	return <LazyModal onClose={dismiss} />;
});

const LazyModal = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-modernize-confluence-ipm-modal" */ './Modal'))
			.Modal,
});
