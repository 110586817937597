import React from 'react';

import { fg } from '@confluence/feature-gating';
import { LoadableLazy } from '@confluence/loadable';

const LazyStandardToPremiumModal = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-StandardToPremiumModal" */ './StandardToPremiumModal'
			)
		).StandardToPremiumModal,
});

export const StandardToPremiumModalMountComponent = () => {
	if (fg('edition_awareness_standard_upsell_confluence_ab_fg')) {
		return <LazyStandardToPremiumModal />;
	}

	return null;
};
