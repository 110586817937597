import React, { useState, type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import FeedbackCollector, { FeedbackFlag } from '@atlaskit/feedback-collector';
import { FlagGroup } from '@atlaskit/flag';
import Link from '@atlaskit/link';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { fg } from '@confluence/feature-gating';
import { useSessionData } from '@confluence/session-data';
import { useNav4OptOut } from '@confluence/nav4-enabled';

import { useNav4FeedbackCollectorStore } from './Nav4FeedbackCollectorStore';

const NAV_4_FEEDBACK_ENTRYPOINT_ID = '5002b4f2-9df8-4bb2-8798-56005ef6cbfc';
const NAV_4_FEEDBACK_GROUP_ID = 'confluence-nav-4-sidebar';

const DEFAULT_CONTEXT = [
	{
		id: 'feedback-group-id',
		value: NAV_4_FEEDBACK_GROUP_ID,
	},
];

export const Nav4FeedbackCollectorModal = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { userId, displayName, locale } = useSessionData();
	const [{ isOpen, optingOut }, { close, openOptOutModal }] = useNav4FeedbackCollectorStore();
	const [shouldDisplayFlag, setShouldDisplayFlag] = useState(false);
	const intl = useIntl();
	const { updateNav4OptOut } = useNav4OptOut();

	const onSubmit = () => {
		if (optingOut) {
			void updateNav4OptOut(true);

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'switchBackOldNavigation',
					source: 'nav4FeedbackCollector',
				},
			}).fire();
		} else {
			setShouldDisplayFlag(true);
			void close();
		}
	};

	const context = optingOut
		? [...DEFAULT_CONTEXT, { id: 'opt-out', value: 'true' }]
		: DEFAULT_CONTEXT;

	const getFeedbackTitleDetails = () => {
		if (optingOut) {
			return intl.formatMessage(i18n.feedbackCollectorOptOutTitleDetails);
		}

		if (fg('confluence_nav_4_opt')) {
			return (
				<>
					{intl.formatMessage(i18n.feedbackCollectorTitleDetailsOptOut, {
						a: (chunks: ReactNode[]) => (
							// eslint-disable-next-line jsx-a11y/anchor-is-valid
							<Link href="#" onClick={openOptOutModal}>
								{chunks}
							</Link>
						),
					})}
				</>
			);
		}

		return intl.formatMessage(i18n.feedbackCollectorTitleDetails);
	};

	return (
		<>
			{isOpen && (
				<FeedbackCollector
					locale={locale}
					name={displayName ?? undefined}
					atlassianAccountId={userId ?? undefined}
					entrypointId={NAV_4_FEEDBACK_ENTRYPOINT_ID}
					onClose={close}
					onSubmit={onSubmit}
					showTypeField={!optingOut}
					feedbackTitle={
						optingOut
							? intl.formatMessage(i18n.feedbackCollectorOptOutTitle)
							: intl.formatMessage(i18n.feedbackCollectorTitle)
					}
					feedbackTitleDetails={getFeedbackTitleDetails()}
					customTextAreaLabel={optingOut ? intl.formatMessage(i18n.customTextAreaLabel) : undefined}
					submitButtonLabel={optingOut ? intl.formatMessage(i18n.submitButtonLabel) : undefined}
					additionalFields={[
						{
							id: 'customfield_10047',
							value: context.map(({ id, value }) => `${id}: ${String(value)}`).join('\n'),
						},
					]}
				/>
			)}
			<FlagGroup onDismissed={() => setShouldDisplayFlag(false)}>
				{shouldDisplayFlag && <FeedbackFlag />}
			</FlagGroup>
		</>
	);
};

const i18n = defineMessages({
	feedbackCollectorTitle: {
		id: 'nav4-feedback.feedback-collector.title',
		defaultMessage: 'Help us improve the new navigation',
		description: 'Title for the nav4 feedback collector modal',
	},
	feedbackCollectorTitleDetails: {
		id: 'nav4-feedback.feedback-collector.title-details',
		defaultMessage: 'We’d love to hear about your experience with the new navigation.',
		description: 'Message for the sub-title for the nav4 feedback collector modal',
	},
	feedbackCollectorTitleDetailsOptOut: {
		id: 'nav4-feedback.feedback-collector.title-details.opt-out',
		defaultMessage:
			'We’d love to hear about your experience with the new navigation. If needed, you can <a>switch back to the old navigation</a>.',
		description:
			'Message for the sub-title for the nav4 feedback collector modal. Should match page-layout.nav4.feedback-collector.title-details but include a link to opt out.',
	},
	feedbackCollectorOptOutTitle: {
		id: 'nav4-feedback.feedback-collector.opt-out.title',
		defaultMessage: 'Switch back to the old navigation',
		description: 'Title for the nav4 feedback collector modal when opting out',
	},
	feedbackCollectorOptOutTitleDetails: {
		id: 'nav4-feedback.feedback-collector.opt-out.title-details',
		defaultMessage:
			'We’re sad to see you go. To help us improve the navigation for all users, please share why the new navigation didn’t work well for you.',
		description: 'Message for the sub-title for the nav4 feedback collector modal when opting out',
	},
	customTextAreaLabel: {
		id: 'nav4-feedback.feedback-collector.custom-text-area-label',
		defaultMessage: 'Let us know why you want to switch back',
		description: 'Label for the custom text area when opting out',
	},
	submitButtonLabel: {
		id: 'nav4-feedback.feedback-collector.submit-button-label',
		defaultMessage: 'Switch back',
		description: 'Label for the submit button when opting out',
	},
});
