import { fg } from '@confluence/feature-gating';
import {
	deserializeState,
	useGetOnboardingState,
	useSetOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { useSessionData, AccessStatus } from '@confluence/session-data';
import { useIsProductAdmin } from '@confluence/current-user';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { MODERNIZE_CONFLUENCE_IPM_MODAL_ONBOARDING_STATE_KEYS } from '../constants/onboardingStateKeys';

const isLicensed = (accessStatus: AccessStatus | null) =>
	accessStatus === AccessStatus.LICENSED_USE_ACCESS ||
	accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;

const atLeastTwo = (...features: boolean[]) =>
	features.reduce((count, feature) => count + (feature ? 1 : 0), 0) >= 2;

const useHasEnoughFeatures = () => {
	const { isProductAdmin } = useIsProductAdmin();
	const isNav4Enabled = useIsNav4Enabled();
	return (
		atLeastTwo(
			fg('confluence_frontend_content_wrapper'),
			fg('confluence_live_pages_open_beta_trait_opted_in'),
			isNav4Enabled,
		) || isProductAdmin
	);
};

export const useModernizeModal = () => {
	const { accessStatus } = useSessionData();
	const { data, loading, error } = useGetOnboardingState(
		[...Object.values(MODERNIZE_CONFLUENCE_IPM_MODAL_ONBOARDING_STATE_KEYS)],
		!isLicensed(accessStatus) || !fg('cc_onboarding_modernize_confluence_ipm_modal'),
	);
	const { setOnboardingState } = useSetOnboardingState();

	const { hasDismissedModernizeConfluenceIpmModal } = deserializeState(data);
	const hasEnoughFeatures = useHasEnoughFeatures();
	const dismiss = () => {
		setOnboardingState({
			key: MODERNIZE_CONFLUENCE_IPM_MODAL_ONBOARDING_STATE_KEYS.hasDismissedModernizeConfluenceIpmModal,
			value: 'true',
		});
	};

	if (!fg('cc_onboarding_modernize_confluence_ipm_modal')) {
		return {
			eligible: false,
			dismiss: () => {},
		};
	}

	if (!isLicensed(accessStatus)) {
		return {
			eligible: false,
			dismiss: () => {},
		};
	}

	if (!hasEnoughFeatures) {
		return {
			eligible: false,
			dismiss: () => {},
		};
	}

	if (error) {
		return {
			eligible: false,
			error,
			dismiss: () => {},
		};
	}

	if (loading) {
		return {
			eligible: false,
			loading,
			dismiss: () => {},
		};
	}

	return {
		eligible: !hasDismissedModernizeConfluenceIpmModal,
		dismiss,
	};
};
